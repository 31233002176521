import React, { ReactElement } from 'react';
import { Box, HStack, Text } from '@chakra-ui/react';
import { graphql, PageProps } from 'gatsby';
import { ComingSoon } from '../components/ComingSoon';
import { CTA } from '../components/CTA';
import { DateTime } from '../components/DateTime';
import { Hero } from '../components/Hero';
import { Icon } from '../components/Icon';
import { ImageFromUrlProps } from '../components/ImageFromUrl';
import { ImageRasterProps } from '../components/ImageRaster';
import { Main } from '../components/Main';
import { RichText, RichTextData } from '../components/RichText';
import { usePageContext } from '../context/PageContext';
import { ColorScheme } from '../theme/colors';
import { isPast } from '../utils/datetime';

const TRANS = {
  buyTickets: {
    en: 'Buy tickets',
    fi: 'Osta liput',
  },
};

export type EventPostQuery = {
  contentfulPageEventPost: {
    contentful_id: string;
    title: string;
    description?: string;
    slug: string;
    image: {
      gatsbyImageData?: ImageRasterProps['image'];
      fixed?: { src: ImageFromUrlProps['image'] };
    };
    colorScheme: ColorScheme;
    start: string;
    end?: string;
    venueName?: string;
    venueAddress?: string;
    ticketPrice?: string;
    ticketInfo?: string;
    ticketUrl?: string;
    content?: RichTextData;
  };
};

const EventPost = ({ data }: PageProps<EventPostQuery>): ReactElement => {
  const { locale } = usePageContext();

  const {
    title,
    image,
    colorScheme,
    start,
    end,
    venueName,
    venueAddress,
    ticketPrice,
    ticketInfo,
    ticketUrl,
    content,
  } = data.contentfulPageEventPost;

  return (
    <Main
      variant={'twoColumn'}
      pageTitle={title}
      hero={
        <Hero
          heroImage={image}
          heroColorScheme={colorScheme}
          variant={'narrow'}
        />
      }
      rightColumn={
        <Box
          backgroundColor={`${colorScheme}.500`}
          color={'text.onColor'}
          padding={'1.5rem'}
          borderRadius={'m'}
          sx={{
            '& p:first-of-type': { marginTop: 0 },
            '& p:last-of-type': { marginBottom: 0 },
          }}
        >
          <Text marginBottom={'0.5rem'}>
            <DateTime start={start} end={end} condensed />
          </Text>
          {venueName || venueAddress ? (
            <Text fontSize={'xs'} fontWeight={700} marginBottom={'0.5rem'}>
              <HStack align={'flex-start'} as={'span'}>
                <Icon.MapMarker fontSize={'1.5em'} />
                <span>
                  {venueName}
                  {venueName && venueAddress ? ', ' : ''}
                  {venueAddress}
                </span>
              </HStack>
            </Text>
          ) : null}
          {ticketPrice ? (
            <Text fontSize={'xs'} fontWeight={700} marginBottom={'0.5rem'}>
              <HStack align={'flex-start'} as={'span'}>
                <Icon.Ticket fontSize={'1.5em'} />
                <span>{ticketPrice}</span>
              </HStack>
            </Text>
          ) : null}
          {ticketInfo ? (
            <Text fontSize={'xs'} marginTop={'1rem'} marginBottom={'0.5rem'}>
              {ticketInfo}
            </Text>
          ) : null}
          {ticketUrl && !isPast(start) ? (
            <CTA
              variant={'solid'}
              fullWidth
              href={ticketUrl}
              marginTop={'1.5rem'}
            >
              {TRANS.buyTickets[locale]}
            </CTA>
          ) : null}
        </Box>
      }
    >
      {!content ? <ComingSoon /> : <RichText data={content} />}
    </Main>
  );
};

export default EventPost;

export const query = graphql`
  query ($id: String!) {
    contentfulPageEventPost(id: { eq: $id }) {
      ... on ContentfulPageEventPost {
        ...EventPost
      }
    }
  }
`;
